<template>

<app-content :loading="is.loading" :fullheight="true">

	<app-content-head title="Reports" :is-app="true" app-action="scan" v-on:action="is.scanning = true" />

	<app-content-body :no-padding="true" class="report" v-if="!is.loading">

		<com-scan :allowClose="true" :allowManual="true" v-on:scanned="onScanDetected" v-on:close="is.scanning = false" :failed="is.failed" :checking="is.checking" v-if="is.scanning" />

		<div class="report-content">

			<app-input-suggest :loading="is.checking" v-model="model.game" :manual="true" v-on:selected="onScanDetected"  :validation="$v.model.game" api="organisation/library/game" image="image" placeholder="Search game by name" />
			<app-input-toggle v-model="model.status" :fullWidth="true" :validation="$v.model.status" :options="$constants.checkout.report.statusName" />
			<app-input-text :autogrow="true" placeholder="Report details..." v-model="model.message" :validation="$v.model.message" />

		</div>

		<div class="report-actions">

			<app-button :disabled="$v.$invalid" text="Confirm" :loading="is.saving" theme="blue" v-on:click="onConfirmClick" />
			<app-button :disabled="is.saving" text="Cancel" theme="grey" v-on:click="onCloseClick" />

		</div>

	</app-content-body>

</app-content>

</template>

<script>

import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

export default {

	mixins: [validationMixin],

	components: {
		'com-scan': () => import('./common/Scan')
	},

	data: function() {
	
		return {
			is: {
				failed: false,
				loading: false,
				checking: false,
				scanning: false
			},
			model: {
				game: 0,
				status: 1,
				message: ''
			}
		}

	},

	validations: {
		model: {
			game: {
				required
			},
			message: {
				required
			}
		}
	},

	created: function() {

		this.$pubsub.$on('scan', this.onScanDetected)

	},

	beforeDestroy: function() {

		this.$pubsub.$off('scan', this.onScanDetected)

	},

	methods: {

		onConfirmClick: function() {

			this.is.saving = true
			this.is.failed = false

			this.$api.post('reports/report/new', this.model).then(function(json) {

				this.$router.push({
					name: 'Reports.Report',
					params: {
						id: json.report
					}
				})

			}.bind(this), function() {

				this.is.failed = true
				this.is.saving = false

			}.bind(this))

		},

		onCloseClick: function() {

			this.$router.push({
				name: 'Reports.Dashboard'
			})

		},

		onScanDetected: function(code) {

			this.is.checking = true
			this.is.failed = false

			this.$api.get(['reports/game', code]).then(function(json) {

				if (json.report) {

					this.$router.push({
						name: 'Reports.Report',
						params: {
							id: json.report
						}
					})

				} else {

					this.model.game = json.game

					this.is.checking = false
					this.is.scanning = false

				}

			}.bind(this), function() {

				this.is.scanning = false
				this.is.failed = true
				this.is.checking = false

			}.bind(this))

		}

	}

}

</script>

<style scoped>

.report {
	background-color: #fff;
	display: flex;
	flex-direction: column;
}

.report-content {
	flex-grow: 1;
	padding: 10px;
}

.report-actions {
	flex-shrink: 0;
	padding: 10px 20px;
	display: flex;
	justify-content: center;
	background-color: #384352;
}

.report-actions >>> .button {
	width: calc(50% - 10px);
}

</style>
